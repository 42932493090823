import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { FooterTitle } from './title/FooterTitle'
import SendIcon from '@material-ui/icons/Send';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import db from '../firebase.config';
import { useSnackbar } from 'notistack'
import { Copyright } from './Copyright'

export const Footer = () => {
    const [newsletter, setNewsletter] = React.useState('')
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = (event) => {
        event.preventDefault()
        if(newsletter !== ""){
            db.collection("newsletter").add({
                email:newsletter
            })
            .then((docRef) => {
                 enqueueSnackbar("Inscription a la newsletter réussi",{
                    variant:"success"
                })
            })
            .catch((error) => {
                enqueueSnackbar("Opération échouée. Veuillez réessayer.",{
                    variant:"error"
                })
            });
        } else {
            enqueueSnackbar("Veuillez indiquer votre adresse mail.",{
                variant:"warning"
            })
        }
    }
    return (
        <footer className="bloc footer" style={{position:"relative", color:"#fff", backgroundImage:`url(${process.env.PUBLIC_URL}/assets/image/Rectangle20.png)`}}>
            <div className="w80 footer-contact-info">
            <Container>
                <Row>
                    <Col className="bloc-orange-negative">
                        <Row className="full-height">
                        <div className="image-bloc-orange" style={{position:"absolute", top:"0", width:"100%"}}>
                                <img className="lazyload" width="100" height="100" style={{width:"100%", height:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/footer/bloc-orange-left.png"} alt="yatesMed" />
                        </div> 
                            <Col xs={4} md={12} lg={4} style={{padding:"20px"}}>
                                <img width="100" height="50" style={{height:"auto"}}  className="bloc-footer-icon lazyload" data-src={process.env.PUBLIC_URL+"/assets/icon/map.png"} alt="adresse yatesmed" />
                            </Col>
                            <Col xs={8} md={12} lg={8} style={{padding:"15px"}}>
                                <div className="center-vertical">
                                <h5>Adresse</h5>
                                    <p style={{width:"max-content"}}>73 Avenue Ahmed AL Hansali, <br/> Magasin
                                    nᵒ1, Temara</p>
                                </div>    
                            </Col>
                        </Row>
                    </Col>
                    <Col className="bloc-orange-negative">
                        <Row className="full-height">
                        <div className="image-bloc-orange" style={{position:"absolute", top:"0", width:"100%"}}>
                                <img className="lazyload" width="100" height="100" style={{width:"100%", height:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/footer/bloc-orange-center.png"} alt="yatesMed" />
                        </div> 
                            <Col xs={4} md={12} lg={4} style={{padding:"15px"}}>
                                <img className="bloc-footer-icon lazyload" width="100" height="50" style={{height:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/icon/tel.png"} alt="contact yatesmed" />
                            </Col>
                            <Col xs={8} md={12} lg={8} style={{padding:"15px"}}>
                                <div className="center-vertical">
                                    <h5>TELEPHONE</h5>
                                    <p>+212 662 746 731 <br/>+212 537 413 765 <br/>+212 666 859 117</p>
                                </div>
                                
                            </Col>
                        </Row>
                    </Col>
                    <Col className="bloc-orange-negative">
                        <Row className="full-height">
                        <div className="image-bloc-orange" style={{position:"absolute", top:"0", width:"100%"}}>
                                <img className="lazyload" width="100" height="100" style={{width:"100%", height:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/footer/bloc-orange-right.png"} alt="yatesMed" />
                        </div> 
                            <Col xs={4} md={12} lg={4} style={{padding:"15px", paddingRight:"0px"}}>
                                <img className="bloc-footer-icon lazyload" width="100" height="50" style={{height:"auto"}} data-src={process.env.PUBLIC_URL+"/assets/icon/email.png"} alt="email yatesmed" />
                            </Col>
                            <Col xs={8} md={12} lg={8} style={{padding:"15px", paddingLeft:"0px"}}>
                                <div className="center-vertical">
                                <h5>EMAIL</h5>
                                <p style={{paddingBottom:"10px", whiteSpace:"nowrap"}}>info@yatesmed.ma</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>             
                </Row>
            </Container>
            </div>
            <Container fluid={true} style={{paddingBottom:"70px"}}>
            <Row className="justify-content-md-center">
            <Col xs={11}>
            <Row className="justify-content-center">
            <Col className="footer-item" xs={12} sm={10} lg={4}>
                <FooterTitle title="A PROPOS DE NOUS" />
                <p>Yates Med, distributeur d’équipement médical, est votre référence en ligne dans le secteur de la santé. Nous proposons une gamme complète d’équipement médical de qualité, innovant et performant pour vous permettre de faire votre travail avec facilité et précision.
                </p>
                {/*<a href="#" className="ref-client">Voir nos références clients</a>*/}
            </Col>
            <Col className="footer-item" xs={12} sm={10} lg={4} >
                <FooterTitle title="LIENS UTILES" />
                <div className="flexBox liens-utils">
                <div className="flexBox flexBox-col">
                <a href="/articles/mobilier">Mobilier</a>
                <a href="/articles/matelas">Matelas</a>
                <a href="/articles/oxygénothérapie">Oxygénothérapie</a>
                <a href="/articles/diagnostics">Diagnostics</a>
                <a href="/articles/aide a la marche">Aide a la marche</a>
                </div>
                <div className="flexBox flexBox-col liens-utils-seconde-col" style={{marginLeft:"20px"}}>
                <a href="/articles/radiologie">Radiologie</a>
                <a href="/articles/anatomie">Anatomie</a>
                <a href="/mentions-legales">Mention légales</a>
                <a href="/contact">Contacter</a>
                </div>
                </div>
            </Col>
            <Col className="footer-item" xs={12} sm={10} lg={4} >
                <FooterTitle title="NEWSLETTER" />
                <p>Soyez les premiers à être informés des
                nouveautés de yatesmed, inscrivez vous
                à notre newsletter. </p>
                <form onSubmit={handleSubmit}>
                    <div className="input-custume">
                    <input type="email" placeholder="Votre adresse email ..." value={newsletter} onChange={(e) => setNewsletter(e.target.value)} />
                    <button type="submit" ><img className="lazyload"  data-src={process.env.PUBLIC_URL+"/assets/icon/send.png"} alt="newsletter yatesmed" /></button>
                    </div>
                </form>
            </Col>
            </Row>
            </Col>
            </Row>
        </Container>
            <Copyright />
        </footer>
    )
}
