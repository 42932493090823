import React from 'react'

export const FilterList = ({setFiltre, item, typeActive}) => {
    const [active, setActive] = React.useState(false)

    const changeFilter = (event) => {
        if(event.target.classList.contains("active")){
            event.target.className = "listItem"
            setFiltre("")
        } else {
            var x = window.document.getElementsByClassName('listItem')
            for(let i =0; i< x.length; i++){
                x[i].className='listItem';  
            }
            event.target.className = "listItem active"
            setFiltre(item.type)
        }
    }
    return item.type !== typeActive? (
            
                <li className={`listItem ${item.type}`} onClick={changeFilter}>{item.type[0].toUpperCase()+item.type.slice(1, item.type.length)}</li>
            
    ):(
        <li className={`listItem ${item.type} active`} onClick={changeFilter}>{item.type[0].toUpperCase()+item.type.slice(1, item.type.length)}</li>
    )
}
