import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CookiesProvider } from 'react-cookie';


const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
}

ReactDOM.render(
  <React.StrictMode>
  <CookiesProvider>
  <SnackbarProvider maxSnack={3} 
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            ref={notistackRef}
            action={(key) => (
            <Button onClick={onClickDismiss(key)}>
            <CloseIcon />
            </Button> 
            )}>
    <App />
    </SnackbarProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
serviceWorker.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

