import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import Grid from '@material-ui/core/Grid';
import { Container, Hidden } from '@material-ui/core';
import { Phone, Email } from '@material-ui/icons';
export const Navigation = ({ articles }) => {

    return (
        <Navbar className="navigation" sticky="top" collapseOnSelect expand="lg" variant="light" style={{height:"65px",padding:"5px", background:"#2EB0FA"}}>
            <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
            <Navbar.Collapse id="responsive-navbar-nav">
            <Container className="container-navigation">
                <Nav className="flexBox f-between w100" style={{right:"0"}}>
                <Nav.Link style={{color:"#fff"}} href="/">Accueil</Nav.Link>
                    <NavDropdown title="Mobilier" id="collasible-nav-dropdown">
                        <NavDropdown.Item href={`/articles/mobilier`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Mobilier</h6></NavDropdown.Item>
                        {
                            articles.mobilier?.map((type) => (
                                <NavDropdown.Item href={`/articles/mobilier/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                            ))
                        }
                    </NavDropdown>
                    <NavDropdown title="Matelas" id="collasible-nav-dropdown">
                        <NavDropdown.Item href={`/articles/matelas`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Matelas</h6></NavDropdown.Item>
                        {
                            articles.matelas?.map((type) => (
                                <NavDropdown.Item href={`/articles/matelas/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                            ))
                        }
                    </NavDropdown>
                    <NavDropdown title="Consommable" id="collasible-nav-dropdown">
                        <NavDropdown.Item href={`/articles/consommable`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Consommable</h6></NavDropdown.Item>
                        {
                            articles.consommable?.map((type) => (
                                <NavDropdown.Item href={`/articles/consommable/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                            ))
                        }
                    </NavDropdown>
                    <NavDropdown title="Oxygénothérapie" id="collasible-nav-dropdown">
                        <NavDropdown.Item href={`/articles/oxygénothérapie`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Oxygénothérapie</h6></NavDropdown.Item>
                        {
                            articles.oxy?.map((type) => (
                                <NavDropdown.Item href={`/articles/oxygénothérapie/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                            ))
                        }
                    </NavDropdown>
                    <NavDropdown title="Diagnostic" id="collasible-nav-dropdown">
                        <NavDropdown.Item href={`/articles/diagnostics`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Diagnostic</h6></NavDropdown.Item>
                        {
                            articles.diagnostics?.map((type) => (
                                <NavDropdown.Item href={`/articles/diagnostics/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                            ))
                        }
                    </NavDropdown>
                    <NavDropdown title="Aide a la marche" id="collasible-nav-dropdown">
                        <NavDropdown.Item href={`/articles/aide a la marche`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Aide a la marche</h6></NavDropdown.Item>
                        {
                            articles.marche?.map((type) => (
                                <NavDropdown.Item href={`/articles/aide a la marche/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                            ))
                        }
                    </NavDropdown>
                    <NavDropdown title="Radiologie" id="collasible-nav-dropdown">
                        <NavDropdown.Item href={`/articles/radiologie`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Radiologie</h6></NavDropdown.Item>
                        {
                            articles.radiologie?.map((type) => (
                                <NavDropdown.Item href={`/articles/radiologie/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                            ))
                        }
                    </NavDropdown>
                    <NavDropdown title="Anatomie" id="collasible-nav-dropdown">
                        <NavDropdown.Item href={`/articles/anatomie`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Anatomie</h6></NavDropdown.Item>
                        {
                            articles.anatomie?.map((type) => (
                                <NavDropdown.Item href={`/articles/anatomie/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                            ))
                        }
                    </NavDropdown>
                    <Nav.Link style={{color:"#fff"}} href="/contact">Contact</Nav.Link>
                </Nav>
            </Container>
            </Navbar.Collapse>
        </Navbar>
    
    )
}
{/*<React.Fragment>

            <Navbar className="navigation" sticky="top" collapseOnSelect expand="lg" variant="light" style={{ paddingBottom: "0", paddingTop: "0", background: "rgba(255,255,255,0.9)", paddingLeft: "0", paddingRight: "0", borderTop: "1px solid #7A7A7A", boxShadow: "0 -3px 28px rgba(0,0,0,.11)", top: "-80px" }}>
                <Grid container style={{ height: "100%" }} justify="center">
                    <Grid item xs={12} lg={12} style={{ height: "100%" }}>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav" style={{ height: "100%" }}>
                            <Hidden only={["xs", "sm", "md"]} >
                                <Grid container style={{ width: "100%" }}>
                                    <Grid item lg={12} className="flexBox" style={{ width: "100%", borderBottom: "1px solid rgba(122, 122, 122, 0.3)", height: "80px" }}>
                                        <div className="square" style={{ textAlign: 'center' }}>
                                            <Nav.Link className="logo" href="/" style={{ padding: "15px" }}>
                                                <img className="lazyload" data-src={process.env.PUBLIC_URL + '/assets/logo/Logos_YatesMed 1.png'} width="100" height="100" style={{ position: "relative", height: "100%", width: "auto" }} alt="yatesmed" />
                                            </Nav.Link>
                                        </div>
                                        <Nav className="flexBox navigation-link" style={{ flexGrow: 1, justifyContent: "space-evenly" }}>
                                            <Nav.Link href="/">Accueil</Nav.Link>
                                            <Nav.Link href="/contact">Contact</Nav.Link>
                                        </Nav>
                                        <div className="flexBox info-top-section" style={{ justifyContent: "flex-end" }}>
                                            <div className="flexBox info-top-section" style={{ position: "relative", height: "max-content", top: "50%", transform: "translateY(-50%)" }}>
                                                <Phone />
                                                <div className="flexBox" style={{ marginLeft: "10px" }}>
                                                    <p style={{ whiteSpace: "nowrap" }} className="no-padding">+212 537 413 765</p>
                                                </div>
                                            </div>
                                            <div className="flexBox info-top-section" style={{ position: "relative", height: "max-content", top: "50%", transform: "translateY(-50%)" }}>
                                                <Email />
                                                <p style={{ whiteSpace: "nowrap", marginLeft: "10px" }} className="no-padding">info@yatesmed.ma</p>
                                            </div>

                                        </div>
                                    </Grid>
                                    <Grid item lg={12} style={{ height: "80px" }}>
                                        <Nav className="flexBox f-around navigation-link" style={{ flexGrow: 1 }}>
                                            <NavDropdown title="Mobilier" id="collasible-nav-dropdown">
                                                <NavDropdown.Item href={`/articles/mobilier`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Mobilier</h6></NavDropdown.Item>
                                                {
                                                    articles.mains?.map((type) => (
                                                        <NavDropdown.Item href={`/articles/mobilier/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                                    ))
                                                }
                                            </NavDropdown>
                                            <NavDropdown title="Matelas" id="collasible-nav-dropdown">
                                                <NavDropdown.Item href={`/articles/matelas`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Matelas</h6></NavDropdown.Item>
                                                {
                                                    articles.mains?.map((type) => (
                                                        <NavDropdown.Item href={`/articles/matelas/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                                    ))
                                                }
                                            </NavDropdown>
                                            <NavDropdown title="Oxygénothérapie" id="collasible-nav-dropdown">
                                                <NavDropdown.Item href={`/articles/oxygénothérapie`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Oxygénothérapie</h6></NavDropdown.Item>
                                                {
                                                    articles.mains?.map((type) => (
                                                        <NavDropdown.Item href={`/articles/Oxygénothérapie/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                                    ))
                                                }
                                            </NavDropdown>
                                            <NavDropdown title="Diagnostic" id="collasible-nav-dropdown">
                                                <NavDropdown.Item href={`/articles/diagnostics`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Diagnostic</h6></NavDropdown.Item>
                                                {
                                                    articles.mains?.map((type) => (
                                                        <NavDropdown.Item href={`/articles/diagnostics/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                                    ))
                                                }
                                            </NavDropdown>
                                            <NavDropdown title="Aide a la marche" id="collasible-nav-dropdown">
                                                <NavDropdown.Item href={`/articles/aide a la marche`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Aide a la marche</h6></NavDropdown.Item>
                                                {
                                                    articles.mains?.map((type) => (
                                                        <NavDropdown.Item href={`/articles/aide a la marche/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                                    ))
                                                }
                                            </NavDropdown>
                                            <NavDropdown title="Radiologie" id="collasible-nav-dropdown">
                                                <NavDropdown.Item href={`/articles/radiologie`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Radiologie</h6></NavDropdown.Item>
                                                {
                                                    articles.mains?.map((type) => (
                                                        <NavDropdown.Item href={`/articles/radiologie/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                                    ))
                                                }
                                            </NavDropdown>
                                            <NavDropdown title="Anatomie" id="collasible-nav-dropdown">
                                                <NavDropdown.Item href={`/articles/anatomie`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Anatomie</h6></NavDropdown.Item>
                                                {
                                                    articles.mains?.map((type) => (
                                                        <NavDropdown.Item href={`/articles/anatomie/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                                    ))
                                                }
                                            </NavDropdown>
                                        </Nav>
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden only={["lg", "xl"]} >
                                <Nav className="flexBox f-around navigation-link" style={{ flexGrow: 1 }}>
                                    <Nav.Link href="/">Accueil</Nav.Link>
                                    <NavDropdown title="Mobilier" id="collasible-nav-dropdown">
                                        <NavDropdown.Item href={`/articles/mobilier`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Mobilier</h6></NavDropdown.Item>
                                        {
                                            articles.mains?.map((type) => (
                                                <NavDropdown.Item href={`/articles/mobilier/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                    <NavDropdown title="Matelas" id="collasible-nav-dropdown">
                                        <NavDropdown.Item href={`/articles/matelas`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Matelas</h6></NavDropdown.Item>
                                        {
                                            articles.mains?.map((type) => (
                                                <NavDropdown.Item href={`/articles/matelas/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                    <NavDropdown title="Oxygénothérapie" id="collasible-nav-dropdown">
                                        <NavDropdown.Item href={`/articles/oxygénothérapie`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Oxygénothérapie</h6></NavDropdown.Item>
                                        {
                                            articles.mains?.map((type) => (
                                                <NavDropdown.Item href={`/articles/Oxygénothérapie/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                    <NavDropdown title="Diagnostic" id="collasible-nav-dropdown">
                                        <NavDropdown.Item href={`/articles/diagnostics`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Diagnostic</h6></NavDropdown.Item>
                                        {
                                            articles.mains?.map((type) => (
                                                <NavDropdown.Item href={`/articles/diagnostics/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                    <NavDropdown title="Aide a la marche" id="collasible-nav-dropdown">
                                        <NavDropdown.Item href={`/articles/aide a la marche`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Aide a la marche</h6></NavDropdown.Item>
                                        {
                                            articles.mains?.map((type) => (
                                                <NavDropdown.Item href={`/articles/aide a la marche/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                    <NavDropdown title="Radiologie" id="collasible-nav-dropdown">
                                        <NavDropdown.Item href={`/articles/radiologie`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Radiologie</h6></NavDropdown.Item>
                                        {
                                            articles.mains?.map((type) => (
                                                <NavDropdown.Item href={`/articles/radiologie/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                    <NavDropdown title="Anatomie" id="collasible-nav-dropdown">
                                        <NavDropdown.Item href={`/articles/anatomie`}><h6 style={{ textDecoration: "underline", fontWeight: "bold" }}>Anatomie</h6></NavDropdown.Item>
                                        {
                                            articles.mains?.map((type) => (
                                                <NavDropdown.Item href={`/articles/anatomie/${type}`}>{type[0].toUpperCase()+type.slice(1)}</NavDropdown.Item>
                                            ))
                                        }
                                    </NavDropdown>
                                    <Nav.Link href="/contact">Contact</Nav.Link>
                                </Nav>
                            </Hidden>
                        </Navbar.Collapse>
                    </Grid>
                </Grid>
            </Navbar>
        </React.Fragment>
*/}