import React from 'react'
import { Container } from 'react-bootstrap'
import Image from 'react-bootstrap/Image'
export const HeaderArticle = ({categorie, image}) => {
    return (
        <Container className="headerArticle" fluid={true} style={{padding:"0"}}>
        <div>
        <h2>{categorie}</h2>
        </div>
        <Image src={image} fluid style={{width:"100%"}} />
        </Container>
    )
}
