import React, { Fragment, useEffect, useState } from "react";
import { HeaderArticle } from "../component/HeaderArticle";
import { Col, Row, Container } from "react-bootstrap";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import db from "../firebase.config";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { BackButton } from "../component/BackButton";
import { MetaTags } from "../component/MetaTags";
import marked from "marked";
import {
  JSONLD,
  Graph,
  Product,
  ItemReviewed,
  Review,
  Author,
  Location,
  Rating,
  Generic,
  AggregateRating,
  GenericCollection,
} from "react-structured-data";
import { loader } from "lazysizes";
import { ArticleLoader } from "../component/loader/ArticleLoader";

export const Article = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [articleSimilar, setArticleSimilar] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  // const waitdata = async () => {
  //     if(id){
  //         let data = []
  //         let tag = []
  //         let similar = []
  //         const query = await db.collection('products').doc(id).get()
  //         data = query.data()
  //         if(!data){
  //             history.push("/404")
  //         } else {
  //             data.id = query.id
  //             tag = [... data.tag].slice(0, 10)
  //             setArticle(data)
  //             // firebaseApp.firestore().collection('products').where("tag","array-contains-any", tag).limit(5)
  //             // .onSnapshot(querySnapshot => {
  //             //     querySnapshot.docChanges().forEach(change => {
  //             //     let object = change.doc.data()
  //             //     object.id = change.doc.id
  //             //     similar.push(object)

  //             //     });
  //             //     setArticleSimilar(similar)
  //             //     console.log("similar : ", similar)
  //             // });
  //         }

  //       } else{
  //           history.push(`/404`)
  //       }
  // }

  React.useEffect(() => {
    try {
      setLoading(true);
      window.scrollTo(0, 0);
      var docRef = db.collection("products").doc(id);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            let dt = doc.data();
            let transform = dt.description.split("\n");
            console.log("my desc : ", transform);
            dt.description = transform.map(
              (e) => e[0]?.toUpperCase() + e.slice(1) + "\n"
            );
            setArticle(dt);
            // article similar
            let tag = [];
            let similar = [];
            tag = [...doc.data().tag].slice(0, 10);
            db.collection("products")
              .where("tag", "array-contains-any", tag)
              .limit(5)
              .onSnapshot((querySnapshot) => {
                querySnapshot.docChanges().forEach((change) => {
                  let object = change.doc.data();
                  object.id = change.doc.id;
                  similar.push(object);
                });
                setArticleSimilar(similar);
              });
            setLoading(false);
            console.log("similar : ", similar);
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
            history.push(`/404`);
          }
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          history.push(`/404`);
        });
    } catch (error) {
      console.log("error load ressource");
      history.push(`/`);
    }
  }, [id]);
  let inc = 0;

  const getMarkdownText = (t) => {
    var rawMarkup = marked(t, { sanitize: true });
    return { __html: rawMarkup };
  };

  return (
    <div>
      {article && articleSimilar ? (
        <MetaTags
          meta={{
            title: `${article.nom.charAt(0).toUpperCase() + article.nom.slice(1)}, YatesMed Maroc`,
            description:`${article.nom.charAt(0).toUpperCase() + article.nom.slice(1)}, YatesMed Maroc , distributeur d’équipement médical, est votre référence en ligne dans le secteur de la santé. Nous permettons aux établissements hospitaliers, aux thérapeutes physiques, aux dentistes, aux vétérinaires, aux centres de conditionnement physique, aux établissements de soins de longue durée d’exercer leur métier grâce à un matériel adapté.`,
            keywords: article.tag.map(e => e),
            url: window.location.href,
            image: article.images[0],
          }}
        />
      ) : null}
      <BackButton />
      <Container style={{ marginBottom: "50px" }} fluid={true}>
        {!loading ? (
          <React.Fragment>
            <Row>
              <Col xs={12} lg={5}>
                <Carousel transitionTime="500">
                  {article.images &&
                    article.images.map((image) => (
                      <div
                        className="carousel-image-md"
                        style={{
                          position: "relative",
                          top: "50%",
                          transform: "translateY(-50%)",
                          height: "250px",
                        }}
                      >
                        <img
                          style={{ height: "100%", width: "auto" }}
                          src={image}
                          alt={article.nom + " yatesmed Maroc."}
                        />
                      </div>
                    ))}
                </Carousel>
              </Col>
              <Col xs={12} lg={7}>
                <h4 style={{ marginBottom: "25px" }}>
                  {article.nom &&
                    article.nom.charAt(0).toUpperCase() + article.nom.slice(1)}
                </h4>
                <span>{`Ref : ${article.ref && article.ref}`}</span>
                <h5 className="description-distance">Descriptif : </h5>
                <pre autoCapitalize>
                  <p className="article-descripton">{article.description}</p>
                </pre>
                <div className="flexBox f-between">
                  <div>
                    <h5 className="description-distance">Prix : </h5>
                    <p>{article.prix!== 0?`${article.prix.toFixed(2)} DHS Brut TTC`:"En Attende"}</p>
                  </div>
                  <div>
                    {article.tailles ? (
                      <React.Fragment>
                        <h5 className="description-distance">Tailles : </h5>
                        <p>
                          {article.tailles.charAt(0).toUpperCase() +
                            article.tailles.slice(1)}
                        </p>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="bloc justify-content-center">
              <Col xs={12}>
                <div style={{ width: "max-content" }}>
                  <h3 style={{ fontWeight: "bold" }}>Articles similaires</h3>
                  <div
                    style={{
                      background: "#2EB0FA",
                      height: "5px",
                      width: "50%",
                      position: "relative",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                  ></div>
                </div>
              </Col>
              {articleSimilar &&
                articleSimilar.map((item) => {
                  if (item.id !== article.id && inc < 4) {
                    inc = inc + 1;
                    return (
                      <Col xs={8} sm={5} md={4} lg={3}>
                        <Link
                          to={`/article/${item.id}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="similaires-article-bloc">
                            <div
                              style={{ height: "200px", overflow: "hidden" }}
                            >
                              <img
                                style={{ maxHeight: "200px" }}
                                src={item.images[0]}
                                alt={item.nom + " yatesmed Maroc."}
                              />
                            </div>
                            <div
                              style={{
                                backgroundColor: "#fff",
                                zIndex: "5",
                                position: "relative",
                              }}
                            >
                              <span style={{ color: "#9D9D9D" }}>
                                {item.marque}
                              </span>
                              <h6
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                  marginTop: "10px",
                                  lineHeight: "1.5em",
                                  height: "3em",
                                  overflow: "hidden",
                                  color: "#2EB0FA",
                                }}
                              >
                                {item.nom[0].toUpperCase() +
                                  item.nom.slice(1, item.nom.lenght)}
                              </h6>
                              <span style={{ color: "#9D9D9D" }}>
                                {item.ref}
                              </span>
                              <p
                                style={{
                                  color: "#565656",
                                  textAlign: "right",
                                  fontWeight: "bold",
                                }}
                              >
                                {item.prix} DHS TTC
                              </p>
                            </div>
                            <div className="filter-hover">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-search"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </div>
                          </div>
                        </Link>
                      </Col>
                    );
                  }
                })}
            </Row>
          </React.Fragment>
        ) : (
          <ArticleLoader />
        )}
      </Container>
    </div>
  );
};
