import { Typography } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { Col, Row, Container } from "react-bootstrap";

export const ArticleLoader = () => {
    return (
        <>
        <Row>
            <Col xs={12} lg={5}>
            <Skeleton variant="rect" height={300} />
            <ul className="flexBox" style={{marginTop:"15px", paddingLeft:"0px"}}>
            <Skeleton style={{marginRight:"15px"}} variant="rect" width="80px" height="80px" />
            <Skeleton style={{marginRight:"15px"}} variant="rect" width="80px" height="80px" />
            <Skeleton style={{marginRight:"15px"}} variant="rect" width="80px" height="80px" />
            <Skeleton style={{marginRight:"15px"}} variant="rect" width="80px" height="80px" />
            </ul>
            </Col>
            <Col xs={12} lg={7}>
                <Typography component="div" variant="h4">
                <Skeleton width="40%"/>
                </Typography>
                <Skeleton width="30%"/>
                <Typography component="div" variant="h5">
                <Skeleton width="40%"/>
                </Typography>
                <Skeleton variant="rect" height={250} />
                <Typography component="div" variant="h5">
                    <Skeleton width="40%"/>
                </Typography>
                <div className="article-taille">
                    <ul className="flexBox">
                    <Skeleton style={{marginRight:"15px"}} variant="rect" width="50px" height="50px" />
                    <Skeleton style={{marginRight:"15px"}} variant="rect" width="50px" height="50px" />
                    <Skeleton style={{marginRight:"15px"}} variant="rect" width="50px" height="50px" />
                    <Skeleton style={{marginRight:"15px"}} variant="rect" width="50px" height="50px" />
                    </ul>
                </div>
            </Col>
        </Row>
        <Row className="bloc justify-content-center">
            <Col xs={12}>
                <div style={{width:"40%"}}>
                    <Typography component="div" variant="h3">
                        <Skeleton/>
                    </Typography>
                    <div style={
                        {background:"#FFD102",
                        height:"5px", 
                        width:"50%", 
                        position:"relative",
                        left:"50%",
                        transform:"translateX(-50%)"
                    }}></div>
                </div>
            </Col>
            
            <Col xs={8} sm={5} md={4} lg={3}>
                <div className="similaires-article-bloc" style={{border:"none"}}>
                <Skeleton variant="rect" height={118} />
                <div className="skeleton">
                <Skeleton width="35%" />
                <Skeleton />
                <Skeleton width="20%" />
                </div>
                </div>
            </Col> 
            <Col xs={8} sm={5} md={4} lg={3}>
                <div className="similaires-article-bloc" style={{border:"none"}}>
                <Skeleton variant="rect" height={118} />
                <div className="skeleton">
                <Skeleton width="35%" />
                <Skeleton />
                <Skeleton width="20%" />
                </div>
                </div>
            </Col> 
            <Col xs={8} sm={5} md={4} lg={3}>
                <div className="similaires-article-bloc" style={{border:"none"}}>
                <Skeleton variant="rect" height={118} />
                <div className="skeleton">
                <Skeleton width="35%" />
                <Skeleton />
                <Skeleton width="20%" />
                </div>
                </div>
            </Col> 
            <Col xs={8} sm={5} md={4} lg={3}>
                <div className="similaires-article-bloc" style={{border:"none"}}>
                <Skeleton variant="rect" height={118} />
                <div className="skeleton">
                <Skeleton width="35%" />
                <Skeleton />
                <Skeleton width="20%" />
                </div>
                </div>
            </Col>             
        </Row>
        </>
    )
}
