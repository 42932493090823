import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { Facebook, Twitter, Linkedin, Youtube } from 'react-bootstrap-icons';
import { Phone, Email } from '@material-ui/icons';
export const InfoSection = () => {
    return (
        <div className="min-bloc infoSection" style={{ background: "#fff", color: "#6F6F6E" }}>
            <Container fluid={true}>
                <Row className="justify-content-center">
                    <Col lg={2} xs={6} sm={3}>
                        <div className="square">
                            <a href="/" className="logo" href="/" style={{position:"relative"}}>
                            <img className="lazyload" data-src={process.env.PUBLIC_URL +'/assets/logo/Logos_YatesMed 1.png'} style={{position:"relative"}} alt="yatesmed" />
                            </a>
                        </div>
                    </Col>
                    <Col sm={9} style={{justifyContent:"center"}}>
                        <div className="flexBox info-top-section" style={{justifyContent:"flex-end"}}>
                            <div className="flexBox info-top-section">
                                <Phone />
                                <div className="flexBox" style={{marginLeft:"10px"}}>
                                    <p style={{ whiteSpace: "nowrap" }} className="no-padding">+212 537 413 765</p>
                                </div>
                            </div>
                            <div className="flexBox info-top-section">
                                <Email />
                                <p style={{ whiteSpace: "nowrap", marginLeft:"10px" }} className="no-padding">info@yatesmed.ma</p>
                            </div>

                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
