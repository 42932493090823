import React from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { TitleLine } from './title/TitleLine';

export const Presentation = () => {
    return (
        <Container fluid={true} className="bloc">
            <Row className="justify-content-md-center">
                <Col xs={12} lg={11}>
                    <Row className="presentation">
                        <Col xs={12} lg={6}>
                            <div className="presentation" style={{width:"90%", position:"relative",left:"50%", transform:"translateX(-50%)"}}>
                            <Row>
                                <Col className="presentation-text">
                                    <TitleLine title="Yates Med, la réponse à vos besoins en matériel médical"/>
                                    <p>Yates Med, distributeur d’équipement médical, est votre référence en ligne dans le secteur de la santé. Nous permettons aux établissements hospitaliers, aux thérapeutes physiques, aux dentistes, aux vétérinaires, aux centres de conditionnement physique, aux établissements de soins de longue durée d’exercer leur métier grâce à un matériel adapté. Yates Med s’engage à fournir à ses clients la meilleure expérience possible en matière d'approvisionnement. Nous proposons une gamme complète d’équipement pour la prévention des infections, d’instruments de précision, d’équipements chirurgicaux et bien plus encore! Découvrez dès aujourd’hui notre vaste gamme de produits et envoyez-nous une demande de devis!</p>
                                </Col>
                            </Row>
                            </div> 
                        </Col>
                        <Col xs={12} lg={6} style={{padding:"0px", textAlign:"center"}}>
                        <img className="lazyload" width="100" height="100" style={{width:"90%", height:"auto", position:"relative", top:"0", marginBottom:"25px"}} data-src={process.env.PUBLIC_URL+"/assets/image/image 36.png"} alt="presentation yatesmed" />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    )
}
