import React from 'react'

export const TitleLine = ({ title, width, bottom }) => {
    const w = width || "20%"
    const b = bottom || "70px"
    return (
        <div style={{ marginBottom: b, position: "relative", width: "100%" }}>
            <div style={{whiteSpace:"pre-wrap", overflowWrap: "break-word"}}>
                <h1 className="my-heading-two" style={{ color: "#2EB0FA" }}><span>{title}</span></h1>
            </div>
        </div>
    )
}
