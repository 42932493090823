import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const Pilier = () => {
    return (
        <div style={{ marginTop: "80px", marginBottom: "120px" }}>
            <Container fluid>
                <Row className="justify-content-center">
                    <Col xs={11}>
                        <Row className="justify-content-center">
                            <Col xs={8} sm={6} md={6} lg={3} className="col-bloc">
                                <div className="pilier">
                                    <img src={process.env.PUBLIC_URL + "/assets/icon/performance.png"} />
                                    <h4>Performance</h4>
                                    <p>Vous obtenez chez Yates Med des produits de haute qualité et innovants. avec nos solutions flexibles, un temps de réponse rapide, des spécialistes de terrain, une excellente réputation et croissance nous assurons que votre entreprise recevra une attention particulière et un service de haute qualité.</p>
                                </div>
                            </Col>
                            <Col xs={8} sm={6} md={6} lg={3} className="col-bloc">
                                <div className="pilier">
                                    <img src={process.env.PUBLIC_URL + "/assets/icon/diversite.png"} />
                                    <h4>Diversité</h4>
                                    <p>Notre gamme de produits est en expansion constante. Notre vaste sélection vous permet de comparer différentes marques et de partir en sachant que vous obtiendrez ce qui correspond le mieux à vos besoins.</p>
                                </div>
                            </Col>
                            <Col xs={8} sm={6} md={6} lg={3} className="col-bloc">
                                <div className="pilier">
                                    <img src={process.env.PUBLIC_URL + "/assets/icon/qualite.png"} />
                                    <h4>Qualité</h4>
                                    <p>Nous proposons divers produits de marques leader. Vous y trouverez certainement tout l'équipement médical dont vous avez besoin. Nous comprenons à quel point il peut être difficile de trouver des produits et notre sélection vous aide à choisir parmi les meilleurs options sur le marché.</p>
                                </div>
                            </Col>
                            <Col xs={8} sm={6} md={6} lg={3} className="col-bloc">
                                <div className="pilier">
                                    <img src={process.env.PUBLIC_URL + "/assets/icon/choix.png"} />
                                    <h4>Choix</h4>
                                    <p>Yates Med possède l'une des plus grandes sélections de produits et d'équipements médicaux en ligne. Nous nous démarquons par notre variété et notre qualité. Faites de nous un guichet unique pour les produits médicaux.</p>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
