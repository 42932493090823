import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { ArticlesLoader } from "./loader/ArticlesLoader";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const ListArticles = ({
  filtre,
  articles,
  setSearchWord,
  setFiltre,
  loader,
}) => {
  const classes = useStyles();
  return (
    <Col xs={12} lg={9}>
      <div className="flexBox f-between">
        <Dropdown></Dropdown>
        {/*<div className="searchBar" style={{border:"solid 1px #A8A8A8", borderRadius:"15px", height:"min-content", padding:"0", width:"35%", minWidth:"150px"}}>
            <input onChange={e => {
                var x = window.document.getElementsByClassName('listItem')
                for(let i =0; i< x.length; i++){
                    x[i].className='listItem';  
                }
                setFiltre("")
                setSearchWord(e.target.value)}
            } 
            type="text" className="search-query" placeholder="Rechercher..." style={{border:"none",background:"rgba(0,0,0,0)", padding:"10px", paddingLeft:"15px", width:"80%"}} />        
            <label className="btn"><img style={{width:"70%"}} src={process.env.PUBLIC_URL+"/assets/icon/search.png"} alt="protetion de la tête yatesmed" /></label>
        </div>*/}
      </div>
      <Container fluid>
        {!loader ? (
          <Row className="bloc">
            {articles &&
              articles.map((item) => {
                if (filtre === item.type || filtre === "") {
                  return (
                    <Col xs={10} lg={4} className="liste-article">
                      <Link
                        to={`/article/${item.id}`}
                        style={{ textDecoration: "none" }}
                      >
                        <div className="list-article-bloc">
                          <div
                            style={{
                              height: "180px",
                              position: "relative",
                              overflow: "hidden",
                            }}
                          >
                            <img
                              className="image-article"
                              src={item.images[0]}
                              alt={item.nom + " yatesmed maroc"}
                            />
                          </div>
                          <div className="ref">
                            <img
                              className="rectangle-article"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/article/Rectangle.png"
                              }
                            />
                            <p
                              style={{ marginRight: "5px", color: "#fff" }}
                            >{`Ref : ${item.ref}`}</p>
                          </div>
                          <p
                            style={{
                              marginRight: "5px",
                              textAlign: "right",
                              fontWeight: "bold",
                            }}
                          >
                          {}
                            {item.prix === 0?"En Attende":`${item.prix} DHS TTC`}
                          </p>
                          <p className="produit-name-reduce" style={{ marginRight: "5px", textAlign: "right" }}>
                            {item.nom[0].toUpperCase() +
                              item.nom.slice(1, item.nom.length)}
                          </p>
                          <div style={{ textAlign: "right" }}>
                            <span style={{ marginRight: "5px" }}>
                              {item.marque.toUpperCase()}
                            </span>
                          </div>
                          <div className="filter-hover">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-search"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                          </div>
                        </div>
                      </Link>
                    </Col>
                  );
                }
              })}
          </Row>
        ) : (
          <ArticlesLoader />
        )}
      </Container>
    </Col>
  );
};
