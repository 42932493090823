import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { TitleLine } from "./title/TitleLine";
import db from "../firebase.config";
import { Link } from "react-router-dom";

export const NosProduit = () => {
  const [produits, setProduits] = React.useState([]);

  React.useEffect(() => {
    let similar = [];
    db.collection("products")
      .where("vedette", "==", true)
      .limit(8)
      .onSnapshot((querySnapshot) => {
        querySnapshot.docChanges().forEach((change) => {
          let object = change.doc.data();
          object.id = change.doc.id;
          similar.push(object);
        });
        setProduits(similar);
      });
  }, []);

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col xs={11} className="bloc-nos-produit">
          <div style={{ marginTop: "15px", marginLeft: "15px" }}>
            <TitleLine title="Nos produits" />
          </div>

          <Row className="justify-content-center">
            {produits.map((item) => (
              <Col
                xs={10}
                sm={8}
                md={6}
                lg={4}
                xl={3}
                style={{ height: "100%", position: "relative" }}
                className="liste-article"
              >
                <Link
                  to={`/article/${item.id}`}
                  style={{ textDecoration: "none" }}
                >
                  
                  <div className="list-article-bloc">
                    <div
                      style={{
                        height: "180px",
                        position: "relative",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        className="image-article"
                        src={item.images[0]}
                        alt={item.nom + " yatesmed maroc"}
                      />
                    </div>
                    <div className="ref">
                      <img
                        className="rectangle-article"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/article/Rectangle.png"
                        }
                      />
                      <p
                        style={{ marginRight: "5px", color: "#fff" }}
                      >{`Ref : ${item.ref}`}</p>
                    </div>
                    <p
                      style={{
                        marginRight: "5px",
                        textAlign: "right",
                        fontWeight: "bold",
                      }}
                    >
                      {item.prix} DHS TTC
                    </p>
                    <p className="produit-name-reduce" style={{ marginRight: "5px", textAlign: "right" }}>
                      {item.nom[0].toUpperCase() +
                        item.nom.slice(1, item.nom.length)}
                    </p>
                    <div style={{ textAlign: "right" }}>
                      <span style={{ marginRight: "5px" }}>
                        {item.marque.toUpperCase()}
                      </span>
                    </div>
                    <div className="filter-hover">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                      </svg>
                    </div>
                  </div>
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};
