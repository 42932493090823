import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const ArticlesLoader = () => {
    return (
        <React.Fragment>
        <Col xs={12} lg={12}>
            <Row style={{marginBottom:"250px"}}>
                <Col xs={10} lg={4}>
                    <Skeleton variant="rect" height={118} />
                    <div className="skeleton">
                    <Skeleton width="35%" />
                    <Skeleton />
                    <Skeleton width="20%" />
                    </div>
                </Col>
                <Col xs={10} lg={4}>
                    <Skeleton variant="rect" height={118} />
                    <div className="skeleton">
                    <Skeleton width="35%" />
                    <Skeleton />
                    <Skeleton width="20%" />
                    </div>
                </Col>
                <Col xs={10} lg={4}>
                    <Skeleton variant="rect" height={118} />
                    <div className="skeleton">
                    <Skeleton width="35%" />
                    <Skeleton />
                    <Skeleton width="20%" />
                    </div>
                </Col>
                <Col xs={10} lg={4}>
                    <Skeleton variant="rect" height={118} />
                    <div className="skeleton">
                    <Skeleton width="35%" />
                    <Skeleton />
                    <Skeleton width="20%" />
                    </div>
                </Col>
                <Col xs={10} lg={4}>
                    <Skeleton variant="rect" height={118} />
                    <div className="skeleton">
                    <Skeleton width="35%" />
                    <Skeleton />
                    <Skeleton width="20%" />
                    </div>
                </Col>
                <Col xs={10} lg={4}>
                    <Skeleton variant="rect" height={118} />
                    <div className="skeleton">
                    <Skeleton width="35%" />
                    <Skeleton />
                    <Skeleton width="20%" />
                    </div>
                </Col>
            </Row>
        </Col>
        </React.Fragment>
    )
}
