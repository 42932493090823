import { Skeleton } from '@material-ui/lab'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

export const FilterLoader = () => {
    return (
        <React.Fragment>
        <Col xs={12} lg={3}>
        <Skeleton variant="rect" height={118} style={{marginBottom:"50px"}} />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        <Skeleton variant="text" />
        </Col>
        </React.Fragment>
    )
}
