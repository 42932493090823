import React from 'react'
import { Accueil } from './view/Accueil'
import { Articles } from './view/Articles'
import { Article } from './view/Article'
import { PageError } from './view/404'
import { MentionsLegales } from './view/MentionsLegales'
import { Contact } from './view/Contact'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom";
import { Footer } from './component/Footer'
import { Navigation } from './component/Navigation'
import { InfoSection } from './component/InfoSection'

import db from './firebase.config';
import './App.css';
import { useCookies } from 'react-cookie';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack'
import { Hidden } from '@material-ui/core'


function ScrollToTop() {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}




function App() {

  const [articles, setArticles] = React.useState([])
  const [cookies, setCookie, removeCookie] = useCookies(['newsletter']);
  const [email, setEmail] = React.useState("")
  const { enqueueSnackbar } = useSnackbar()

  window.scrollTo(0, 0)


  React.useEffect(() => {
    if (window.location.hostname == "yatesmed-4ee56.web.app" || 
        window.location.hostname == "yatesmed-4ee56.firebaseapp.com") {
          window.location.href = 'https://yatesmed.ma'; 
    }
    let copyData = []
    var docRef = db.collection('navigation').doc("GPRAj5TCnmG6cd58rZoo")
    docRef.get().then((doc) => {
      if (doc.exists) {
        setArticles(doc.data())
      } else {
        // doc.data() will be undefined in this case
      }
    }).catch((error) => {
      console.log("Error getting document:", error);
    });
  }, [])

  const handleCloseNewsletter = () => {
    let d = window.document.getElementById("newsletter-pop-up")
    d.style.display = "none"

  }

  const handleSubmit = (event) => {
    event.preventDefault()
    if (email !== "") {
      db.collection("newsletter").add({
        email: email
      })
        .then((docRef) => {
          enqueueSnackbar("Inscription a la newsletter réussi", {
            variant: "success"
          })
        })
        .catch((error) => {
          enqueueSnackbar("Opération échouée. Veuillez réessayer.", {
            variant: "error"
          })
        });
    } else {
      enqueueSnackbar("Veuillez indiquer votre adresse mail.", {
        variant: "warning"
      })
    }
  }

  return articles ? (
    <Router>
      <ScrollToTop />
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        
          <InfoSection />
        <Navigation articles={articles} /> 
        <Switch>
          <Route path="/article/:id">
            <Article />
          </Route>
          <Route path="/articles/:categorie/:type">
            <Articles />
          </Route>
          <Route path="/articles/:categorie">
            <Articles />
          </Route>
          <Route path="/articles">
            <Articles />
          </Route>
          <Route path="/mentions-legales">
            <MentionsLegales />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route exact path="/">
            <Accueil />
          </Route>
          <Route path="*">
            <PageError />
          </Route>
        </Switch>
        <div style={{ position: "static", bottom: "0" }}>
          <Footer />
        </div>
      </div>
    </Router>
  ) : null;
}

export default App;
