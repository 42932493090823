import React from 'react'
import { Breadcrumb, Container, Row, Col } from 'react-bootstrap'
import { HeaderArticle } from '../component/HeaderArticle'
import { MetaTags } from '../component/MetaTags'

export const MentionsLegales = () => {
    return (
        <div>
        <MetaTags 
            meta={
                {
                    title:"Yates Med, la réponse à vos besoins en matériel médical", 
                    description:"Yates Med, distributeur d’équipement médical, est votre référence en ligne dans le secteur de la santé. Nous permettons aux établissements hospitaliers, aux thérapeutes physiques, aux dentistes, aux vétérinaires, aux centres de conditionnement physique, aux établissements de soins de longue durée d’exercer leur métier grâce à un matériel adapté.",
                    keywords:"",
                    url:"https://yatesmed.ma/mentions-legales",
                    image:`https://yatesmed.ma/assets/logo/Logos_YatesMed%201.png`,
                }
            } />
            <div className="flexBox f-between" style={{padding:"15px"}}>
                <Breadcrumb style={{backgroundColor:"#fff !important"}}>
                    <Breadcrumb.Item href="/">Accueil</Breadcrumb.Item>
                    <Breadcrumb.Item active>Mentions légales</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <Container className="mention-legale">
                <Row>
                    <Col xs={12}>
                    <p style={{textAlign:"justify"}}>Merci de lire attentivement les différentes modalités d’utilisation du présent site avant d’y parcourir ses pages. En vous connectant sur ce site, vous acceptez sans réserves les présentes modalités. Aussi, conformément à l’article n°6 de la Loi n°2004-575 du 21 Juin 2004 pour la confiance dans l’économie numérique, les responsables du présent site internet www.yatesmed.ma sont :</p>
                    </Col>
                    <Col className="mention-informations" xs={12} lg={12}>
                        <div>
                            <h5>Editeur du Site :</h5>
                            <p>Marc Yates</p>
                            <p>Société : YATESMED</p>
                            <p>Responsable éditorial : YATESMED</p>
                            <p>46 av Oqba, Agdal, Rabat, Maroc</p>
                            <p>Téléphone :+212 537 413 765</p>
                            <p>Email : info@yatesmed.ma</p>
                            <p>Site Web : www.yatesmed.ma</p>
                        </div>
                        <div>
                            <h5>Hébergement : </h5>
                            <p>Hébergeur : FIREBASE</p>
                            <p>188 King ST, San Fransisco, CA 94107, United States</p>
                            <p>Site Web : <a href="https://firebase.google.com">https://firebase.google.com</a></p>
                        </div>
                        <div>
                            <h5>Développement :</h5>
                            <p>YATESMED</p>
                            <p>Adresse : 46 av Oqba, Agdal, Rabat, Maroc</p>
                            <p>Site Web : www.yatesmed.ma</p>
                        </div>
                    </Col>
                </Row>
                <div>
                    <h5 className="jaune">Conditions d’utilisation:</h5>
                    <p>Ce site (www.yatesmed.ma) est proposé en différents langages web (HTML, HTML5, Javascript, CSS, etc…) pour un meilleur confort d’utilisation et un graphisme plus agréable, nous vous recommandons de recourir à des navigateurs modernes comme Internet explorer, Safari, Firefox, Google Chrome, etc…</p>
                    <p>YATESMED met en œuvre tous les moyens dont elle dispose, pour assurer une information fiable et une mise à jour quotidienne de ses sites internet. Toutefois, des erreurs ou omissions peuvent survenir. L’internaute devra donc s’assurer de l’exactitude des informations auprès de l’éditeur de ce site, et signaler toutes modifications du site qu’il jugerait utile</p>
                    <p>Liens hypertextes : Le site internet peut offrir des liens vers d’autres sites internet ou d’autres ressources disponibles sur Internet. YATESMED ne dispose d’aucun moyen pour contrôler les sites en connexion avec ses sites internet. ne répond pas de la disponibilité de tels sites et sources externes, ni ne la garantit. Elle ne peut être tenue pour responsable de tout dommage, de quelque nature que ce soit, résultant du contenu de ces sites ou sources externes, et notamment des informations, produits ou services qu’ils proposent, ou de tout usage qui peut être fait de ces éléments. Les risques liés à cette utilisation incombent pleinement à l’internaute, qui doit se conformer à leurs conditions d’utilisation.</p>
                </div>
                <div>
                    <h5 className="jaune">Produits fournis :</h5>
                    <p>L’ensemble des produits de la société ainsi que ses informations sont présentées sur notre site www.yatesmed.ma. Nos tarifs seront communiqués sur demande du client, par mail, via notre site web www.yatesmed.ma.</p>
                    <p>YATESMED s’efforce de fournir sur le site www.yatesmed.ma des informations aussi précises que possible, les renseignements figurant sur le site www.yatesmed.ma ne sont pas exhaustifs et les photos non contractuelles. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. Par ailleurs, toutes les informations indiquées sur le site www.yatesmed.ma sont données à titre indicatif, et sont susceptibles de changer ou d’évoluer sans préavis.</p>
                </div>
                <div>
                    <h5 className="jaune">Limitation contractuelle sur les données:</h5>
                    <p>Les informations contenues sur ce site sont aussi précises que possible et le site remis à jour à différentes périodes de l’année, mais peut toutefois contenir des inexactitudes ou des omissions. Si vous constatez une lacune, erreur ou ce qui parait être un dysfonctionnement, merci de bien vouloir le signaler par email, à l’adresse info@yatesmed.ma, en décrivant le problème de la manière la plus précise possible (page posant problème, type d’ordinateur et de navigateur utilisé, …).</p>
                    <p>Les liens hypertextes mis en place dans le cadre du présent site internet en direction d’autres ressources présentes sur le réseau Internet ne sauraient engager la responsabilité de YATESMED.</p>
                </div>
                <div>
                    <h5 className="jaune">Propriété intellectuelle: </h5>
                    <p>Tout le contenu du présent sur le site www.yatesmed.ma, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs.</p>
                    <p>Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l’accord exprès par écrit de YATESMED. Cette représentation ou reproduction, par quelque procédé que ce soit, constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle. Le non-respect de cette interdiction constitue une contrefaçon pouvant engager la responsabilité civile et pénale du contrefacteur. En outre, les propriétaires des contenus copiés pourraient intenter une action en justice à votre encontre.</p>
                </div>
                <div>
                    <h5 className="jaune">Litiges:</h5>
                    <p>Les présentes conditions du site www.yatesmed.ma sont régies par les lois marocaines et toute contestation ou litiges qui pourraient naître de l’interprétation ou de l’exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société. </p>
                </div>
                <div>
                    <h5 className="jaune">Données personnelles:</h5>
                    <p>De manière générale, vous n’êtes pas tenu de nous communiquer vos données personnelles lorsque vous visitez notre site Internet www.yatesmed.ma.</p>
                    <p>Cependant, ce principe comporte certaines exceptions. En effet, pour commandés via notre site, vous pouvez être amenés à nous communiquer certaines données telles que : votre nom, votre fonction, le nom de votre société, votre adresse électronique, et votre numéro de téléphone. Tel est le cas lorsque vous remplissez le formulaire qui vous est proposé en ligne, dans la rubrique « contact ». Dans tous les cas, vous pouvez refuser de fournir vos données personnelles. Dans ce cas, vous ne pourrez pas utiliser les services du site, notamment celui de solliciter des renseignements sur notre société, ou de recevoir les lettres d’information.</p>
                    <p>Enfin, nous pouvons collecter de manière automatique certaines informations vous concernant lors d’une simple navigation sur notre site Internet, notamment : des informations concernant l’utilisation de notre site, comme les zones que vous visitez et les services auxquels vous accédez, votre adresse IP, le type de votre navigateur, vos temps d’accès. De telles informations sont utilisées exclusivement à des fins de statistiques internes, de manière à améliorer la qualité des services qui vous sont proposés. Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</p>
                </div>
                <h4 className="gris">Prix et modalités de paiements:</h4>
                <div>
                    <h5 className="jaune">Détermination du prix:</h5>
                    <p>Les prix des produits seront indiqués en DHS MAD TTC. La société YATESMED se réserve le droit de modifier ses prix à tout moment, mais les produits seront facturés sur la base des tarifs en vigueur au moment de l’enregistrement de la commande, sous réserve de disponibilité.</p>
                </div>
                <div>
                    <h5 className="jaune">Modalités de paiement:</h5>
                    <p>Le paiement du client est attendu à 50 % à la commande et le solde à la livraison. Tant que le client ne s’acquitte pas du solde restant, la marchandise demeurera la propriété de la société YATESMED.</p>
                </div>
                <div>
                    <h5 className="jaune">Règlement:</h5>
                    <p>Le paiement des commandes s'effectue soit par:</p>
                    <p>National : (Maroc)</p>
                    <p>Chèque bancaire : En cas de paiement par chèque bancaire, celui-ci doit être émis par une banque domiciliée au Maroc. Au dos du chèque, le client indiquera sa référence de commande afin que le paiement soit relié à son dossier. L’encaissement du chèque est pratiqué à réception de celui-ci à l’adresse suivante : Rabat Guich Oudaya II, Guich oudaya EXT.Riad II, MTAA Temara</p>
                    <p>Le chèque devra être à l’attention de YATESMED</p>
                    <p>Virement bancaire : Le client reçoit par mail, lors de son paiement par virement bancaire, les coordonnées bancaires de la société YATESMED. Dans l’ordre de virement le client doit indiquer la référence de sa commande afin que le paiement puisse être relié à son dossier.</p>
                </div>
                <div>
                    <h5 className="jaune">Livraison:</h5>
                    <p>La livraison est effectuée à l’adresse de livraison indiquée par le client.</p>
                    <p>Pour les produits commandés en grande quantité (plus de 100 pièces) et sur devis, le délais de livraison sera indiqué lors de l'établissement du devis.</p>
                    <p>Les colis sont acheminés par plusieurs sociétés de transports avec lesquels nous avons un partenariat. Les délais de livraison sont ceux indiqués lors du passage de la commande. YATESMED ne pourra être tenue responsable des conséquences dues à un retard d’acheminement n’étant pas de son fait (grève, intempéries...)</p>
                    <p>En cas de colis endommagés (déjà ouverts, produits manquants), le client s’engage à notifier au transporteur et au prestataire par tout moyen, toutes réserves dans les trois (3) jours suivant la réception du produit.</p>
                </div>
                <div>
                    <h5 className="jaune">Droit et rétractation:</h5>
                    <p>Le client particulier peut exercer un droit de rétractation sous forme écrite (par exemple lettre ou e-mail) dans un délai de 14 jours à compter de la réception de la commande et sans indication de motifs ou par le renvoi de la marchandise, à ses frais, si celle-ci lui a été cédée avant l'expiration du délai à l’adresse YATESMED – 73 Avenue Ahmed AL Hansali, Magasin
                    nᵒ1, Temara, accompagnée de la référence de la commande. Les produits retournés doivent être neufs, dans leurs emballages d’origines, non portés, non lavés et non personnalisés. Le client doit signaler à la société YATESMED sur quel point de vente a expédié la marchandise. Le magasin notifiera le client de la réception de la marchandise et se reservera le droit de l’examiner dans un délais de 48 heures afin de constater son authenticité. Pour tous les produits personnalisés, le droit de rétractation ne peut s'appliquer, conformément aux dispositions de l’article L 121-20 du Code de la Consommation tel que modifié par l’ordonnance n°2001-741 du 23 août 2001 qui stipule que le délai de rétractation est de 14 (quatorze) jours, et que la rétractation est notamment exclue (article 121-20.2 3ème alinéa) pour les contrats de fournitures de biens nettement personnalisés ou confectionnés selon les spécifications du consommateur, soit notamment pour les articles sur-mesure, de mesures hors standard ou personnalisés. Ainsi aucun délai de rétractation ne sera accordé s’agissant des articles sur-mesure ou personnalisés.</p>
                     <p>Les remboursements des produits dans les hypothèses visées ci dessus seront effectués par YATESMED dans un délai maximal de trente (30) jours après la réception par elle des dits produits. Le remboursement s’effectuera par virement sur le compte indiqué lors de la commande.</p>
                </div>
                <div>
                    <h5 className="jaune">Divergences techniques et de design :</h5>
                    <p>Dans l’exécution du contrat, nous nous réservons expressément des divergences par rapport aux descriptions et indications de nos dépliants, catalogues et autres documents écrits et électroniques quant à la nature du tissu, la couleur, le poids, la dimension, le design ou des caractéristiques semblables, pour autant que ceux-ci soient raisonnables et acceptables. Des raisons de modification peuvent résulter de fluctuations commerciales et des rythmes techniques de production. La différence de couleur peut quant à elle résulter des différences de réglages des écrans d'ordinateurs ou de la composition du support. Nous nous réservons le droit de remplacer une marque d'un produit par une autre marque de qualité équivalente ou supérieure en cas de rupture de stock.</p>
                </div>
                <div>
                    <h5 className="jaune">Points de vente :</h5>
                    <p>A ce jour, YATESMED compte deux magasins :</p>
                    <p>- Temara, situé au 73 Avenue Ahmed AL Hansali, magasin
                    nᵒ1, Temara</p>
                    <p>Le magasin Temara désserve la région : Temara, Rabat, Salé, Aïn Attig, Skhirat, Ain Aouda, Kénitra, Tifelt, Khémisset, Bouznika, Benslimane, Mohammédia, Casablanca, Berrechid, Settat, El Jadida, Jorf Lasfar</p>
                    <p>La couverture nationale assurée à ce jour via des transporteurs tel que Maroc Express, Amana, Ghazala ... etc</p>
                    <p>YATESMED compte, à moyen terme, mettre en place d’autres points de vente pour assurer une présence physique sur tout le territoire national marocain, notament à : </p>
                    <p>- Fès : afin de désservir la région Boulemane, Meknès, Sidi Kassem, Ifrane, Hajeb, Ribbat Al Khir, Tahla, Taza, Nador, Oujda.</p>
                    <p>- Tanger : afin de couvrir la zone du nord, à savoir Tanger, Tétouan, Ouazzane, Chefchaouen, Assilah, Al Hoceïma, Ksar Sghir, Ksar El-Kébir</p>
                    <p>- Marrakech : sera en charge de désservir la région Marrakech, Benguerir, Essaouira, Azizlal, Beni Mellal, Agadir, Laâyoune, Dakhla</p>
                    <p>YATESMED aura une présence physique dans tout le Maroc.</p>
                </div>
            </Container>
        </div>
    )
}
