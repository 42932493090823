import React from 'react'
import { SliderHeader } from '../component/SliderHeader'
import { Presentation } from '../component/Presentation'
import { ChiffreClefs } from '../component/ChiffreClefs'
import { Confiance } from '../component/Confiance'
import { MetaTags } from '../component/MetaTags'
import {
    JSONLD,
    Generic,
    Review,
   
  } from 'react-structured-data';
import { InfoCovid } from '../component/InfoCovid'
import { NosProduit } from '../component/NosProduit'
import { Pilier } from '../component/Pilier'

export const Accueil = () => {
    return (
        <div>
        
            <MetaTags 
            meta={
                {
                    title:"Yates Med, la réponse à vos besoins en matériel médical", 
                    description:"Yates Med, distributeur d’équipement médical, est votre référence en ligne dans le secteur de la santé. Nous permettons aux établissements hospitaliers, aux thérapeutes physiques, aux dentistes, aux vétérinaires, aux centres de conditionnement physique, aux établissements de soins de longue durée d’exercer leur métier grâce à un matériel adapté.",
                    keywords:"",
                    url:"https://yatesmed.ma",
                    image:`https://yatesmed.ma/assets/logo/Logos_YatesMed%201.png`,
                }
            } />
            <SliderHeader />
            
            <Presentation />
            <NosProduit />
            {/*<ChiffreClefs />*/}
            <Pilier />
        </div>
    )
}
