import React from 'react'
import { Col, Container, Row } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel'
import 'lazysizes';
// import a plugin
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import { Link } from 'react-router-dom';

export const SliderHeader = () => {
    const [index, setIndex] = React.useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };
    return (
        <Container className="carousel-Accueil" fluid={true} style={{ paddingTop: "15px", position: "relative" }}>
            <Row style={{ position: "relative" }}>
                <Col xs={8}>
                    <Carousel className="carousel-Accueil" activeIndex={index} onSelect={handleSelect} style={{ height: "100%" }}>
                        <Carousel.Item interval={2500} style={{ position: "relative", height: "100%" }}>
                            <Carousel.Caption style={{ position: "absolute", top: "0", height: "100%" }}>
                                <Container style={{ position: "relative", height: "100%" }}>
                                    {/*<Row className="justify-content-end" style={{ position: "relative", height: "100%" }}>
                                        <Col xs={6} style={{ position: "relative", height: "100%" }} className="flexBox flexBox-col f-center">
                                            <div className="header-content">
                                                <h1>Yatesmed</h1>
                                                <h3>Votre partenaire <br />santé</h3>
                                                <button className="button" href="#">Depuis 2015</button>
                                            </div>
                                        </Col>
    </Row>*/}
                                </Container>
                            </Carousel.Caption>
                            <img className="header-carousel-bgImage" src={process.env.PUBLIC_URL + "/assets/header/1.svg"} alt="yatesmed" />
                        </Carousel.Item>
                        <Carousel.Item interval={2500} style={{ position: "relative", height: "100%" }}>
                            <Carousel.Caption style={{ position: "absolute", top: "0", height: "100%" }}>
                                <Container style={{ position: "relative", height: "100%" }}>
                                    {/*<Row className="justify-content-end" style={{ position: "relative", height: "100%" }}>
                                        <Col xs={6} style={{ position: "relative", height: "100%" }} className="flexBox flexBox-col f-center">
                                            <div className="header-content">
                                                <h1>Yatesmed</h1>
                                                <h3>Votre partenaire <br />santé</h3>
                                                <button className="button" href="#">Depuis 2015</button>
                                            </div>
                                        </Col>
    </Row>*/}
                                </Container>
                            </Carousel.Caption>
                            <img className="header-carousel-bgImage" src={process.env.PUBLIC_URL + "/assets/header/2.svg"} alt="yatesmed" />
                        </Carousel.Item>
                        <Carousel.Item interval={2500} style={{ position: "relative", height: "100%" }}>
                            <Carousel.Caption style={{ position: "absolute", top: "0", height: "100%" }}>
                                <Container style={{ position: "relative", height: "100%" }}>
                                    {/*<Row className="justify-content-end" style={{ position: "relative", height: "100%" }}>
                                        <Col xs={6} style={{ position: "relative", height: "100%" }} className="flexBox flexBox-col f-center">
                                            <div className="header-content">
                                                <h1>Yatesmed</h1>
                                                <h3>Votre partenaire <br />santé</h3>
                                                <button className="button" href="#">Depuis 2015</button>
                                            </div>
                                        </Col>
    </Row>*/}
                                </Container>
                            </Carousel.Caption>
                            <img className="header-carousel-bgImage" src={process.env.PUBLIC_URL + "/assets/header/3.svg"} alt="yatesmed" />
                        </Carousel.Item>

                    </Carousel>
                </Col>
                <Col>
                <a target="_blank" href="https://mediloc.ma">
                    <div style={{ height: "49%", marginBottom: "20px" }}>
                        <h1 style={{ position: "absolute", opacity: "0" }}>Signalétique rabat maroc</h1>
                        <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + "/assets/header/1small.svg"} width="100" alt="yatesMed" />
                    </div>
                </a>
                <a href="articles/anatomie">
                    <div style={{ height: "49%"}}>
                        <h1 style={{ position: "absolute", opacity: "0" }}>EPI produits personalisable</h1>
                        <img style={{ width: "100%" }} src={process.env.PUBLIC_URL + "/assets/header/2small.svg"} width="100" alt="yatesMed" />
                    </div>
                </a>
                </Col>
            </Row>
        </Container>

    )
}
