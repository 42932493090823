import Button from '@material-ui/core/Button';
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { BackButton } from '../component/BackButton';
import { HeaderArticle } from '../component/HeaderArticle'
import { MetaTags } from '../component/MetaTags';
import emailjs from 'emailjs-com'
import { useSnackbar } from 'notistack'

export const Contact = () => {
    const [form, setForm] = React.useState({
        nomRc:"",
        email:"",
        message:""
    })
    const { enqueueSnackbar } = useSnackbar()

    const handleSubmit = (event) => {
        event.preventDefault()
        let html = {... form}
        emailjs.send("service_ayr6ztn", "template_oas26tn", html, "user_sN408a8B1zTiiLs0iYHrt")
        .then((result) => {
            enqueueSnackbar("Votre Email a bien étais envoyer",{
                variant:"success"
            })
        }, (error) => {
            enqueueSnackbar("Opération échouée. Veuillez réessayer.",{
                variant:"error"
            })
        });
    }
    return (
        <>
        <MetaTags
            meta={
                {
                    title:"YatesMed - Contact", 
                    description:"Yates Med, distributeur d’équipement médical, est votre référence en ligne dans le secteur de la santé. Nous permettons aux établissements hospitaliers, aux thérapeutes physiques, aux dentistes, aux vétérinaires, aux centres de conditionnement physique, aux établissements de soins de longue durée d’exercer leur métier grâce à un matériel adapté.",
                    url:"https://yatesmed.ma/contact",
                    image:`https://yatesmed.ma/assets/logo/Logos_YatesMed%201.png`,
                }
            } />
        <BackButton />
        <div style={{marginBottom:"200px"}}>
            <Container>
                <Row className="justify-content-center row-contact">
                    <Col className="col-contact" xs={10} sm={5} md={5} lg={5}>
                        <div className="contact contact-formulaire">
                            <h3>Envoyer un message</h3>
                            <form onSubmit={handleSubmit}>
                            <input type="txt" placeholder="Nom / raison sociale..." value={form.nomRc} onChange={(e) => setForm({... form, nomRc:e.target.value})} />
                            <input type="email" placeholder="Adresse email..." value={form.email} onChange={(e) => setForm({... form, email:e.target.value})} />
                            <textarea type="txt" value={form.message} onChange={(e) => setForm({... form, message:e.target.value})}></textarea>
                            <Button type="submit" variant="contained">
                                Envoyer
                            </Button>
                            </form>
                        </div>
                    </Col>
                    <Col className="col-contact" xs={10} sm={5} md={5} lg={5}>
                        <div className="contact contact-info">
                            <h3>Contact</h3>
                            <p>Si vous avez une question, utilisez les informations de contact si dessous : </p>
                            <div className="flexBox">
                                <div className="contact-icon">
                                    <img src={process.env.PUBLIC_URL+'/assets/icon/world.svg'} alt="adresse yatesmed" />
                                </div>
                                <div className="contact-right">
                                    <span>Adresse : Temara</span>
                                    <p>73 Avenue Ahmed AL Hansali, Magasin
                                    nᵒ1, Temara</p>
                                </div>
                            </div>
                            <div className="flexBox">
                                <div className="contact-icon">
                                    <img src={process.env.PUBLIC_URL+'/assets/icon/send2.svg'} alt="email" />
                                </div>
                                <div>
                                    <span>Email :</span>
                                    <p style={{width:"max-content"}}>info@yatesmed.ma</p>
                                </div>
                            </div>
                            <div className="flexBox">
                                <div className="contact-icon">
                                    <img src={process.env.PUBLIC_URL+'/assets/icon/tel2.svg'} alt="contact" />
                                </div>
                                <div>
                                    <span>Téléphone :</span>
                                    <p style={{padding:"0 !important", margin:"0px"}}>+212 662 746 731</p>
                                    <p style={{padding:"0 !important", margin:"0px"}}>+212 537 413 765</p>
                                    <p>+212 666 859 117</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                    
                </Row>
            </Container>
        </div>
        </>
    )
}
