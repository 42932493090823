import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAUgct60HFDY6_zjAPsGtMaY-lZeJAGQ7E",
  authDomain: "yatesmed-4ee56.firebaseapp.com",
  projectId: "yatesmed-4ee56",
  storageBucket: "yatesmed-4ee56.appspot.com",
  messagingSenderId: "72736405869",
  appId: "1:72736405869:web:2b8e7526adf42739914264",
  measurementId: "G-RQJX64K2YD"
};
  
const firebaseApp = firebase.initializeApp(firebaseConfig);
firebase.analytics()
const db = firebase.firestore()

export default db;