import React from 'react'

export const FooterTitle = ({title}) => {
    return (
        <div style={{ marginBottom:"20px"}}>
            <h2 style={{color:"#fff"}}>{title}</h2>
            <div style={{height:"2px", backgroundColor:"#2EB0FA", width:"10%"}}></div>
        </div>
    )
}
