import React from 'react'
import { Breadcrumb, Col, Container, Row } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router'
import { FilterArticles } from '../component/FilterArticles'
import { HeaderArticle } from '../component/HeaderArticle'
import { ListArticles } from '../component/ListArticles'
import { MetaTags } from '../component/MetaTags'
import db from '../firebase.config';
import Pagination from '@material-ui/lab/Pagination';
import { ArticlesLoader } from '../component/loader/ArticlesLoader'
import { HeaderImage } from '../data/HeaderCategorie'

export const Articles = () => {
    const { categorie, type } = useParams();
    const [articles, setArticles] = React.useState([])
    const [filtre, setFiltre] = React.useState("")
    const [searchWord, setSearchWord] = React.useState(categorie)
    const history = useHistory();
    const [page, setPage] = React.useState(1);
    const [lastPage, setLastPage] = React.useState(1);
    const [loader, setLoader] = React.useState(true)
    const [totalPages, settotalPages] = React.useState(1)

    const changedataPage = (p, lastP) => {
        let copyData = []
        console.log("calcule : ",)
        db.collection('products').where("tag", "array-contains", searchWord).orderBy("type").startAfter(lastPage).limit(12)
            .onSnapshot(querySnapshot => {
                setLastPage(querySnapshot.docs[querySnapshot.docs.length - 1])
                querySnapshot.docChanges().forEach(change => {
                    let object = change.doc.data()
                    object.id = change.doc.id
                    copyData.push(object)

                });
                setArticles(copyData)
            })
    }


    const handleChange = (event, value) => {
        setPage(value);
        changedataPage(value, value - 1)
    };
    React.useEffect(async () => {
        setLoader(true)
        if (type) {
            setFiltre(type)
        }
        if (!searchWord) {
            let copyData = []
            console.log("je rentre dans le if")
            db.collection('products').orderBy("type")
                .onSnapshot(querySnapshot => {
                    setLastPage(querySnapshot.docs[querySnapshot.docs.length - 1])
                    querySnapshot.docChanges().forEach(change => {
                        let object = change.doc.data()
                        object.id = change.doc.id
                        copyData.push(object)

                    });
                    setArticles(copyData)
                    setLoader(false)
                });
        } else {
            let copyData = []
            console.log("mon type : ", type)
            db.collection('products').where("tag", "array-contains", searchWord)
                .onSnapshot(querySnapshot => {
                    setLastPage(querySnapshot.docs[querySnapshot.docs.length - 1])
                    querySnapshot.docChanges().forEach(change => {
                        let object = change.doc.data()
                        object.id = change.doc.id
                        copyData.push(object)

                    });
                    setArticles(copyData)
                    setLoader(false)
                });
        }
    }, [searchWord])


    return (
        <React.Fragment>
            <MetaTags
                meta={
                    {
                        title: articles && articles.length > 0 && searchWord ? articles[0].categorie.charAt(0).toUpperCase() + articles[0].categorie.slice(1) : "Yates Med, la réponse à vos besoins en matériel médical",
                        description: "Yates Med, distributeur d’équipement médical, est votre référence en ligne dans le secteur de la santé. Nous permettons aux établissements hospitaliers, aux thérapeutes physiques, aux dentistes, aux vétérinaires, aux centres de conditionnement physique, aux établissements de soins de longue durée d’exercer leur métier grâce à un matériel adapté.",
                        keywords: "",
                        url: window.location.href,
                        image: articles && articles.length > 0 ? articles[0].images[0] : `https://yatesmed.ma/assets/logo/Logos_YatesMed%201.png`,
                    }
                } />
            <div>
                <HeaderArticle categorie={categorie.charAt(0).toUpperCase() + categorie.slice(1)} image={HeaderImage[categorie]} />

                <div className="flexBox f-between Breadcrumb" style={{ padding: "15px", marginTop:"50px" }}>
                    <Breadcrumb style={{ backgroundColor: "#fff !important" }}>
                        {
                            categorie !== undefined ? (
                                <React.Fragment>
                                    <Breadcrumb.Item href="#">Accueil</Breadcrumb.Item>
                                    <Breadcrumb.Item active>{categorie.charAt(0).toUpperCase() + categorie.slice(1)}</Breadcrumb.Item>
                                </React.Fragment>
                            ) : null
                        }
                    </Breadcrumb>
                    <div style={{ padding: "19px" }}>
                        <p>Voir <span>1{/* ici calculer le nombre de page */}</span> sur <span>{articles.length}</span> résultat</p>
                    </div>
                </div>
                <Container style={{ marginBottom: "100px" }} fluid={true}>
                    <Row>
                        <FilterArticles setFiltre={setFiltre} articles={articles} searchWord={searchWord} typeActive={type} loader={loader} categorie={categorie} />
                        <ListArticles filtre={filtre} articles={articles} setSearchWord={setSearchWord} setFiltre={setFiltre} loader={loader} />
                        <Col></Col>
                        {/*<Col xs={12} lg={9}>
            <div className="pagination">
             <p>Page: {page}</p>
             <Pagination count={3} color="primary" onChange={handleChange} showFirstButton showLastButton  />
            </div>
        </Col>*/}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
