import { Button } from '@material-ui/core'
import React from 'react'
import { useHistory } from 'react-router'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export const BackButton = () => {
    const history = useHistory()
    return (
        <div className="back-btn">
            <Button startIcon={<ArrowBackIcon/>} variant="text" onClick={history.goBack}>
                Retour
            </Button>
            </div>
    )
}
